.banner_section .container {
    position: relative;
}

.banner_img {
    margin: 0 -40px;
    overflow: hidden;
    border-radius: 0 40px 0 40px;
}

.banner_content {
    width: 100%;
    max-width: 412px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #fff;
    background-image: url(../../../assets/images/logo_plus.svg);
    background-repeat: no-repeat;
    background-position: 90% 0%;
    padding: 30px 0;
}

.banner_content h1 {
    font-size: 64px;
    line-height: 1.1;
    margin-bottom: 30px;
    color: #fff;
}

.banner_content p {
    font-size: 24px;
    font-family: var(--font2);
    color: var(--peach);
    font-weight: 400;
}

.banner_features {
    display: flex;
    border-top: rgba(255, 255, 255, 0.2) 1px solid;
    padding-top: 20px;
    margin-top: 40px;
}

.banner_features h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: var(--font2);
    font-weight: 700;
    color: #fff;
}

.banner_features h4 img {
    margin-right: 10px;
}

.banner_features h4+h4:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--peach);
    margin: 0 10px;
}

.common_sec {
    padding: 60px 0;
}

.heading_block {
    margin-bottom: 40px;
}

.collection_img {
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: ease 0.3s;
    top: 0;
}

.collection_info h4 {
    font-family: var(--font2);
    font-weight: 700;
}

.collection_block:hover .collection_img {
    top: -5px;
}

.plus_bg {
    background-image: url(../../../assets/images/bg1.png);
    background-size: 100%;
}

.plus_peach_bg {
    background-image: url(../../../assets/images/bg2.png);
    background-size: 100%;
}

.bestseller_product {
    border-radius: 0 20px 0 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 15px 24px;
    background-color: #fff;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    height: 100%;
}

.bestseller_product_img {
    position: relative;
    border-radius: 0 20px 0 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.bestseller_product_img img {
    transition: ease 0.5s;
}

.bestseller_product:hover .bestseller_product_img img {
    transform: scale(1.04);
}

.bestseller_product_img .new_badge {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    background-color: #F88D2C;
    color: #fff;
    padding: 5px 10px;
    border-radius: 0 10px 0 10px;
}

.bestseller_product_img .new_badge.hot {
    background-color: #D70920;
}

.bestseller_product_detail {
    padding: 15px;
    border-radius: 0 0 0 20px;
}

.banner_content.scrub_content {
    max-width: 350px;
    background: none;
}

.scrub_section {
    overflow: hidden;
}

.scrub_section .container {
    min-height: 500px;
    position: relative;
}

.scrub_image {
    position: relative;
    left: 400px;
    border-radius: 0 40px 0 40px;
    overflow: hidden;
    max-width: 1040px;
}



.portfolio_slider_list {
    -webkit-animation: continousSlider 15s linear infinite;
    animation: continousSlider 15s linear infinite;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
}

.portfolio_slider_list_outer {
    overflow: hidden;
    position: relative;
}

.portfolio_slider_list_outer:after {
    content: "";
    position: absolute;
    inset: 0;
    box-shadow: inset #fff 10px 0 20px;
}

.portfolio_slider_list>div {
    margin: 0 10px;
    min-width: 200px;
}

.client_logo_slider {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 100%;
    max-width: 50%;
}

.client_section {
    position: relative;
    min-height: 280px;
}

.client_logo_slider .portfolio_slider_list>div {
    min-width: 250px;
    width: 250px;
}

.client_logo_slider .portfolio_slider_list>div img {
    max-height: 100%;
    max-width: 200px;
    object-fit: cover;
}

@-webkit-keyframes continousSlider {
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@keyframes continousSlider {
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

.news_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.news_header_img {
    width: 60px;
    flex: 0 0 60px;
    margin-right: 15px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.news_header_img img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.news_content {
    padding-bottom: 20px;
}

.custom_card {
    border: 0;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 15px 24px;
    margin-bottom: 30px;
}

.news_slider {
    margin-bottom: -30px;
}

.banner_mobile {
    display: none;
}

.bestseller_slider {
    overflow: hidden;
    /* width: 100%; */
}

.bestseller_slider .swiper-slide{
    height: auto;
    padding-bottom: 30px;
}

.bestseller_slider .container {
    /* overflow: visible; */
}


.bestseller_slider .swiper {
    overflow: visible;
}

@media screen and (max-width:1299px) {
    .banner_content h1 {
        font-size: 55px;
    }

    .banner_content p {
        font-size: 20px;
    }

    .banner_content {
        max-width: 340px;
    }

    .collection_img img {
        max-height: 330px;
    }
}

@media screen and (max-width:1199px) {
    .banner_img {
        margin: 0 -20px;
    }

    .banner_content h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .banner_content p {
        font-size: 18px;
    }

    .banner_content {
        max-width: 260px;
        background-size: 30px;
    }

    .banner_features h4 {
        font-size: 13px;
    }

    .collection_img img {
        max-height: 270px;
    }

    .scrub_image {
        max-width: 700px;
    }

    .scrub_section .container {
        min-height: 350px;
    }

    .client_logo_slider .portfolio_slider_list>div {
        min-width: 230px;
        width: 230px;
    }
}

@media screen and (max-width:991px) {
    .banner_content h1 {
        font-size: 30px;
        margin-bottom: 15px;
    }

    .banner_content p {
        font-size: 16px;
    }

    .banner_content {
        max-width: 220px;
        background-size: 25px;
        background-position: 80% 5%;
    }

    .banner_features h4 {
        font-size: 12px;
    }

    .banner_features {
        padding-top: 15px;
        margin-top: 20px;
    }

    .banner_features h4 img {
        max-width: 12px;
    }

    .common_sec {
        padding: 40px 0;
    }

    .collection_img img {
        max-height: 200px;
    }

    .collection_info h4 {
        font-size: 18px;
    }

    .collection_info h4 img {
        max-width: 15px;
    }

    .font20 {
        font-size: 17px !important;
    }

    .font24 {
        font-size: 20px !important;
    }

    .banner_content.scrub_content {
        max-width: 260px;
    }

    .scrub_image {
        max-width: 500px;
        left: 280px;
    }

    .scrub_section .container {
        min-height: 250px;
    }

}


@media screen and (max-width:767px) {
    .banner_mobile {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-image: url(../../../assets/images/banner_bg_mob.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 70vh;
    }

    .banner_mobile img {
        max-width: 90%;
    }

    .banner_content {
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        max-width: 100%;
        padding-block: 50px;
        background-position: 85% 10%;
    }

    .banner_img {
        margin: 0 -10px;
    }

    .banner_features {
        justify-content: center;
    }

    .banner_content h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .banner_content p {
        font-size: 18px;
    }

    .banner_features h4 {
        font-size: 16px;
    }

    .scrub_image {
        position: relative;
        left: 0;
        max-width: 100%;
    }

    .banner_content.scrub_content {
        position: static;
        transform: none;
        max-width: 100%;
        padding: 0 0 30px;
    }

    .client_logo_slider.portfolio_slider_list_outer {
        position: static;
        transform: none;
        max-width: 100%;
        padding-top: 30px;
    }
}

@media screen and (max-width:575px) {
    .banner_content h1 {
        font-size: 7vw;
        margin-bottom: 4vw;
    }

    .banner_content p {
        font-size: 4vw;
    }

    .banner_features h4 {
        font-size: 3vw;
    }

    .collection_blocks .row {
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
    }

    .collection_blocks .row .col-sm-6 {
        max-width: 70%;
    }

    .portfolio_slider_list_outer::after {
        display: none;
    }
}