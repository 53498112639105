.shop_sidebar {
    width: 100%;
    max-width: 15%;
}

.shop_sidebar .accordion-item {
    border-width: 0 0 1px;
    border-radius: 0;
}

.shop_sidebar .accordion-button:after {
    --bs-accordion-btn-icon-width: 15px;
    opacity: 0.8;
}

.shop_sidebar .accordion-button {
    box-shadow: none !important;
    background-color: transparent !important;
    padding-inline: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font);
    color: var(--text);
}

.shop_sidebar .accordion-body {
    padding-inline: 0;
    padding-top: 10px;
}

.shop_outer {
    padding-left: 3%;
    padding-top: 40px;
    display: flex;
    align-items: flex-start;
    max-width: 100vw;
    margin: 0 auto;
}

.shop_content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.shop_products_container {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 40px;
    margin-bottom: 20px;
}

.shop_products .bestseller_product {
    box-shadow: none;
    border-radius: 20px;
    overflow: hidden;
}

.filter_opt_btns {
    font-size: 12px;
    margin: 0 10px 10px 0;
}

.filter_opt_btns input+span {
    padding: 5px 10px;
    color: var(--text);
    border: rgba(0, 0, 0, 0.1) 1px solid;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.filter_opt_btns input:checked+span {
    background-color: rgba(8, 42, 107, 0.1);
    color: var(--primary);
}

.filter_opt_btns input {
    display: none;
}

.shop_filters_header {
    text-align: left;
}

.shopPproductsSearch .product_grid {
    --count: 4;
    display: grid;
    grid-template-columns: repeat(var(--count), 1fr);
    gap: 20px;
}

@media screen and (min-width:768px) {
    .shop_content {
        flex: 0 0 calc(100% - 180px);
        max-width: calc(100% - 180px);
    }

}

@media screen and (max-width:1400px) {
    .shopPproductsSearch .product_grid {
        --count: 3;
    }
}

@media screen and (min-width:1299px) {
    .shop_sidebar {
        min-width: 180px;
    }
}

@media screen and (max-width:991px) {
    .shopPproductsSearch .product_grid {
        --count: 2;
    }
}

@media screen and (max-width:767px) {
    .shop_outer {
        display: block;
        margin: auto;
        padding-inline: 0;
        padding-top: 20px;
        max-width: 540px;
        width: 100%;
    }

    .shop_sidebar,
    .shop_products_container {
        width: 100%;
        max-width: 100%;
        padding-inline: 15px;
    }

    .shop_sidebar {
        margin-bottom: 30px;
    }

    .shop_filters_accordion {
        display: none;
    }

    .shop_filters_accordion.show {
        display: block;
    }

    .shop_filters_header {
        padding: 10px;
        text-align: center;
        border: rgba(0, 0, 0, 0.1) 1px solid;
        border-radius: 5px;
    }


}


@media screen and (max-width:575px) {}

@media screen and (max-width:480px) {
    .shopPproductsSearch .product_grid {
        --count: 1;
    }
}