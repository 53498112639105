html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:root {
  --primary: #082A6B;
  --peach: #FFC794;
  --text: #6F6F6F;
  --text2: #7e7e7e;
  --font: "Red Hat Text", sans-serif;
  --font2: "Red Hat Display", sans-serif;
  --heading: "Comfortaa", sans-serif;
  --white: #fff;
  --black: #000;
  --textBlack: #000;
}

body {
  background-color: #fff;
  color: var(--text);
  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 700;
  font-family: var(--heading);
  color: var(--primary);
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

p {
  margin: 0 0 10px;
  color: var(--text);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

}

p:last-of-type {
  margin-bottom: 0;
}

ol {
  padding-left: 15px;

}

.formField {
  background-color: #efefef;
  height: 44px;
  padding: 0 15px;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  font-family: var(--font);
  color: var(--black);
  font-weight: 500;
  border: 0;
  outline: 0;
  box-shadow: none;
}

textarea.formField {
  height: auto;
  padding-block: 15px;
  min-height: 44px;
}

.formField::-webkit-input-placeholder {
  color: var(--text);
}

.formField::-moz-placeholder {
  color: var(--text);
}

.formField:-ms-input-placeholder {
  color: var(--text);
}

.formField::-ms-input-placeholder {
  color: var(--text);
}

.formField::placeholder {
  color: var(--text);
}

.formField:focus {
  outline: none;
  box-shadow: none;
}

table {
  width: 100%;
}

strong,
.bold {
  font-weight: 700 !important;
}

a,
button,
textarea {
  transition: ease 0.3s;
  -webkit-transition: ease 0.3s;
  -moz-transition: ease 0.3s;
  text-decoration: none !important;
  outline: none !important;
  color: inherit;
}

a:hover,
button:hover {
  color: inherit;
}

button {
  background: none;
  border: 0;
  padding: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f5f5f5;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 20px;
  border: 0px solid #f5f5f5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #efefef inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.light {
  font-weight: 300 !important;
}

.reg {
  font-weight: 400 !important;
}

.med {
  font-weight: 500 !important;
}

.sbold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.exbold {
  font-weight: 900 !important;
}

.fontPrimary {
  font-family: var(--font) !important;
}

.fontPrimary2 {
  font-family: var(--font2) !important;
}

.fontPrimary3 {
  font-family: var(--heading) !important;
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App>main {
  flex: 1 0 auto;
}



.font10 {
  font-size: 10px !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}

.white-bg {
  background-color: #fff !important;
}

.primary-text {
  color: var(--primary) !important;
}

.primary-bg {
  background-color: var(--primary) !important;
}

.text-dark {
  color: var(--black) !important;
}

.text-white {
  color: var(--white) !important;
}

.text-light {
  color: var(--text) !important;
}

.text-light2 {
  color: var(--text2) !important;
}

.text_danger {
  color: red !important;
}

.common_btn {
  padding: 10px 20px;
  border-radius: 0 5px 0 5px;
  font-size: 16px;
  font-weight: 700;
  border: transparent 1px solid;
  background-color: var(--peach);
  color: var(--primary) !important;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}

.common_btn.btn2 {
  border-radius: 0 10px 0 10px;
  font-weight: 500;
}

.common_btn:hover {
  border-radius: 10px;
}

.common_btn.sm {
  padding: 5px 10px;
  font-size: 10px;
}

.common_btn.primary,
.common_btn.primary:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.common_btn.primary2,
.common_btn.primary2:hover {
  background-color: #efefef;
  color: var(--primary) !important;
}

.common_btn.outlined {
  color: var(--primary);
  border-color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.common_btn.outlined:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff !important;
}

.common_btn.rounded {
  border-radius: 10px !important;
}

.common_btn[disabled] {
  opacity: 0.2;
  pointer-events: none;
}

.common_btn--white {
  background-color: #F5F5F5;
  color: #898989;
  border-color: #fff;
}

.common_btn.common_btn--white:hover {
  background: var(--primary);
  color: #fff;
}

hr {
  opacity: 0.2;
}

.modal-dialog.modal-sm {
  --bs-modal-width: 400px;
}

.modal-header,
.modal-footer,
.modal-body {
  border: 0;
  padding: 20px;
}

.modal-body {
  padding-top: 10px;
}

.modal-header .btn-close {
  padding: 0;
  margin-right: 0px;
}

.modal-content {
  border-radius: 10px;
}

@media (min-width: 576px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 550px;
  }
}
@media (min-width: 767px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 700px;
  }
}
@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 888px;
  }
}

@media screen and (max-width:1199px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }
}


@media screen and (max-width:991px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .common_btn {
    padding: 6px 15px;
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  body{
    font-size: 14px;
  }
  .formField.search_input {
    height: 36px;
    padding: 0 10px;
    font-size: 11px;
  }
}